import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  Copyright,
} from "./styles";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from "@ant-design/icons";

interface SocialLinkProps {
  href: string;
  icon: any
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, icon }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        { icon }
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large href="/#contact">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:support@reliq.co.nz">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>28/29 Aviemore Drive,</Para>
              <Para>Highland Park, </Para>
              <Para>Auckland 2010</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" href="https://spellboundgames.co.nz/blogs/news">
                {t("Blog")}
              </Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "2rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo-purple.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
                <Copyright>&copy; 2021</Copyright>
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink href="https://twitter.com/Reliqnz" icon={<TwitterOutlined style={{ fontSize: 30 }} />} />
              <SocialLink href="https://www.instagram.com/reliq.nz" icon={<InstagramOutlined style={{ fontSize: 30 }} />} />
              <SocialLink href="https://www.facebook.com/reliqnz" icon={<FacebookOutlined style={{ fontSize: 30 }} />} />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
