import { Row, Col, Layout, Rate } from "antd";
import { withTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { ContentWrapper, CardContentBlockSection } from "./styles";
import { CardDto } from "../../services/CardApi";
import moment from "moment";

interface CardContentBlockProps {
  content: CardDto | undefined;
  t: any;
}

const CardContentBlock = ({ content, t }: CardContentBlockProps) => {
  const camelCaseToText = (text: string): string => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  const renderValue = (key: string, value: string) => {
    let elements = [];
    if (key === "edge" || 
        key === "align" ||
        key === "corner" || 
        key === "surface") {
      elements.push(
        <div key={key} style={{ marginBottom: 20 }}>
          <Rate disabled allowHalf defaultValue={value ? parseFloat(value) : 0} />
        </div>
      );
      return elements;
    }
    else {
      if (key === "encaseDate") value = moment(value).format("DD MMM YYYY")
      if (key === "serviceType" && value === "GE") value = "Graded Encapsulation";
      if (key === "serviceType" && value === "EO") value = "Authentic Encapsulation";

      elements.push(<p key={key}>{value}</p>)
      return elements;
    }
  }

  const renderContent = () => {
    const c = content as any;

    return (
      <>
        <h6>{`Reliq # ${content?.cardId}`}</h6>
        <Layout>
          {
            Object.keys(c).map((k, i) => {
              if (k === "cardId") return "";
              if (k === "encaseDate" && !c[k]) return "";
              if (content?.serviceType === "EO" && k === "finalGrade") return "";
              if (content?.serviceType === "EO" && k === "edge") return "";
              if (content?.serviceType === "EO" && k === "align") return "";
              if (content?.serviceType === "EO" && k === "corner") return "";
              if (content?.serviceType === "EO" && k === "surface") return "";
              return (
                <ContentWrapper key={i}>
                  <Row justify="start" align="middle">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <p>{`${camelCaseToText(k)} :`}</p>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {renderValue(k, c[k])}
                    </Col>
                  </Row>
                </ContentWrapper>
              )
            })
          }
        </Layout>
      </>);
  };

  return (
    <CardContentBlockSection>
      <Fade direction="left">
        {
          content ? 
          renderContent()
          :
          <h6>Report Not Found</h6>
        }
      </Fade>
    </CardContentBlockSection>
  );
};

export default withTranslation()(CardContentBlock);
