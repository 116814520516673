import { Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import { Styles } from "../styles/styles";
import Header from "../components/Header";
import Home from "../pages/Home";
import Report from "../pages/Report";

const Router = () => {
  const [isBack, setIsBack] = useState<boolean>(false);

  useEffect(() => {
    const loadScriptByURL = (id: any, url: any, callback?: any) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`, () => {
      window.dispatchEvent(new Event("CaptchaReady"));
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <Styles />
      <Header isBack={isBack} />
      <Switch>
        <Route
          exact
          path={["/", "/home"]}
          render={() => (<Home back={setIsBack} />)}
        />

        <Route
          exact
          path={["/r/*", "/R/*"]}
          render={() => (<Report back={setIsBack} />)}
        />
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
