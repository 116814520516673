import moment from "moment";
import Fetcher from "./Fetcher";

export interface CardDto {
  cardId?: number,
  gameName?: string,
  setName?: string,
  cardName?: string,
  year?: string,
  rarity?: string,
  variant?: string,
  finish?: string,
  language?: string,
  serviceType?: string,
  encaseDate?: moment.Moment,
  finalGrade?: string,
  edge?: string,
  align?: string,
  corner?: string,
  surface?: string
};

interface CardApiProps {
  getCard: (token: string, cardId: number) => Promise<CardDto | undefined>
}

const CardApi: CardApiProps = {
  getCard: async (token: string, cardId: number): Promise<CardDto | undefined> => {
    const resource = `/api/Card/${cardId}`;
    const opts = { method: "GET", headers: { token: token } }; // need to include auth header at later stage

    const res = await Fetcher<CardDto>(resource, opts);
    if (res && res.encaseDate) res.encaseDate = moment(res.encaseDate);
    return res;
  }
}

export default CardApi;