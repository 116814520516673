import { useState } from "react";
import { useHistory } from "react-router";
import { Row, Col, Drawer, PageHeader, Input } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

interface HeaderProps {
  isBack: boolean
}

const Header: React.FC<HeaderProps> = ({ isBack, t }: any) => {
  const [visible, setVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { Search } = Input;

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const onSearch = (value: string) => {
    if (value) {
      history.push(`/R/${value}`);
      setSearchValue("");
    }
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  const Logo = (
    <LogoContainer to="/" aria-label="homepage">
      <SvgIcon src="logo-purple.svg" width="101px" height="64px" />
    </LogoContainer>
  );

  const SearchBox = (
    <Row>
      <Col>
        <Search placeholder="Search Reliq. Number" value={searchValue} onChange={e => setSearchValue(e.target.value)} onSearch={onSearch} />
      </Col>
    </Row>
  );

  const FullHeader = (
    <>
      <Row justify="space-between">
        { Logo }
        <NotHidden>
          <MenuItem />
        </NotHidden>
        <Burger onClick={showDrawer}>
          <Outline />
        </Burger>
      </Row>
      { SearchBox }
      <Drawer closable={false} visible={visible} onClose={onClose}>
        <Col style={{ marginBottom: "2.5rem" }}>
        </Col>
        <MenuItem />
      </Drawer>
    </>
  );

  return (
    <HeaderSection>
      <Container>
        {
          !isBack ?
          FullHeader
          :
          <>
            <PageHeader title="Home" onBack={() => history.push("/")} />
            { SearchBox }
          </>
        }
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
