import styled from "styled-components";
import TimeAgo from "react-timeago";

export const SlideshowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
`;

export const SlideshowSlider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`;

export const Slide = styled.div`
  display: inline-block;

  width: 100%;
  height: 500px;

  @media only screen and (max-width: 1024px) {
    height: 450px;
  }

  @media only screen and (max-width: 768px) {
    height: 350px;
  }

  @media only screen and (max-width: 320px) {
    height: 300px;
  }  
`;

interface SlideImgProps {
  src?: string;
}
export const SlideImg = styled.img<SlideImgProps>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  flexWrap: nowrap;
  
  border: 10px solid white;
`;

interface SlideTimeAgoProps {
  date: string;
}
export const SlideTimeAgo = styled(TimeAgo)<SlideTimeAgoProps>`
  color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
`;

export const SlideshowDots = styled.div`
  text-align: center;
`;

interface SlideshowDotProps {
  isActive?: boolean; 
}
export const SlideshowDot = styled.div<SlideshowDotProps>`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: ${(props) => (props.isActive ? "#2e186a" : "#f1f2f3")};
`;
