import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import CardApi, { CardDto } from "../../services/CardApi";
import Container from "../../common/Container";
import CardContentBlock from "../../components/CardContentBlock";
import Loader from "react-loader-spinner";
import { LoaderContainer } from "./styles";

interface ReportProps {
  back: (flag: boolean) => void
}

const Report: React.FC<ReportProps> = ({ back }) => {
  const location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const [result, setResult] = useState<CardDto | undefined>();
  const [grecaptcha, setGrecaptcha] = useState<any>(window.grecaptcha);

  const handleCaptchaReady = () => {
    setGrecaptcha(window.grecaptcha);
  };

  const getCard = (token: string, id: number) => {
    trackPromise(
      CardApi
        .getCard(token, id)
        .then(res => setResult(res))
    );
  };

  useEffect(() => {
    window.addEventListener("CaptchaReady", handleCaptchaReady);
    return () => window.removeEventListener("CaptchaReady", handleCaptchaReady);
  }, []);

  useEffect(() => {
    back(true);

    if (!grecaptcha) return;

    const params = location.pathname.split("/");
    if (params.length >= 2 && params[2] !== "") {
      grecaptcha.ready(() => {
        trackPromise(
          grecaptcha
            .execute(process.env.REACT_APP_SITE_KEY, { action: "getcard" })
            .then((token: string) => getCard(token, parseInt(params[2])))
        );
      });
    }
    else {
      setResult(undefined);
    }
  }, [back, location, grecaptcha]);

  return (
    <Container>
      {
        promiseInProgress ?
        <LoaderContainer>
          <Loader type="ThreeDots" color="#fff" height="100" width="100" />
        </LoaderContainer>
        :
        <CardContentBlock content={result} />
      }
    </Container>
  );
};

export default Report;