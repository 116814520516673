const Fetcher = async <T>(resource: string, opts: any): Promise<T | undefined> => {
  const options = { 
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    ...opts
  };
  const response = await fetch(resource, options);
  if (response.ok) {
    const text = await response.text();
    try {
      const result = JSON.parse(text);
      return result as T;
    } 
    catch (e) {
      // if the return result is not json
      // but the request is ok with empty result
      return text as any;
    }
  } 
  return undefined;
};

export default Fetcher;