import Fetcher from "./Fetcher";

export interface ImageDto {
  imageUrl: string,
  timestamp: string,
  permalink: string
};

interface InstagramApiProps {
  getImages: (token: string) => Promise<ImageDto[] | undefined>
}

const InstagramApi: InstagramApiProps = {
  getImages: async (token: string): Promise<ImageDto[] | undefined> => {
    const resource = `/api/Instagram`;
    const opts = { method: "GET", headers: { token: token } }; // need to include auth header at later stage

    const res = await Fetcher<ImageDto[]>(resource, opts);
    return res;
  }
}

export default InstagramApi;