import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import InstagramApi, { ImageDto } from "../../services/InstagramApi";

import {
  Slide,
  SlideImg,
  SlideshowContainer, 
  SlideshowDot, 
  SlideshowDots, 
  SlideshowSlider, 
  SlideTimeAgo
} from "./styles";

const Slideshow = () => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState<ImageDto[] | undefined>([]);
  const [grecaptcha, setGrecaptcha] = useState<any>(window.grecaptcha);
  const timeoutRef = useRef<any>(null);
  const delay = 3000;
  
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const getImages = (token: string) => {
    InstagramApi
      .getImages(token)
      .then((response) => {
        setImages(response);
      });
  }

  const handleCaptchaReady = () => {
    setGrecaptcha(window.grecaptcha);
  };

  useEffect(() => {
    window.addEventListener("CaptchaReady", handleCaptchaReady);
    return () => window.removeEventListener("CaptchaReady", handleCaptchaReady);
  }, []);

  useEffect(() => {
    if (grecaptcha && images && images.length === 0) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.REACT_APP_SITE_KEY, { action: "instagram" })
          .then((token: string) => {
            getImages(token);
          });
      });
    }
  }, [grecaptcha, images]);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => 
      setIndex((prevIndex) => 
        prevIndex === (images?.length || 0) - 1 ? 0 : prevIndex + 1
      ),
      delay
    );

    return () => resetTimeout();
  }, [index, images]);

  return (
    <SlideshowContainer>
      <SlideshowSlider style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
        {Array.isArray(images) && images.map((image, index) => {
          return (
            <Slide key={index}>
              <a href={image.permalink} target="_blank" rel="noreferrer">
                <SlideImg src={image.imageUrl} />
              </a>
              <SlideTimeAgo date={image.timestamp} />
            </Slide>
          )
        })}
      </SlideshowSlider>
      <SlideshowDots>
        {Array.isArray(images) && images.map((_, idx) => (
          <SlideshowDot 
            key={idx}
            isActive={index === idx ? true : false}
            onClick={() => setIndex(idx)}
          />
        ))}
      </SlideshowDots>
    </SlideshowContainer>
  );
};

export default withTranslation()(Slideshow);
